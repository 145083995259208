import React, { useState, useEffect, useImperativeHandle, useCallback } from "react";
import ReactPlayer from "react-player";
import { PlayButton, PauseButton } from "./icons";
import { useMediaQuery } from "react-responsive";
import { useOnAir } from "../../hooks/useOnAir";

const Player = React.forwardRef((props, ref) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [isPlaying, setIsPlaying] = useState(false);
  const [mixcloudShow, setMixcloudShow] = useState(undefined);
  const onair = useOnAir();

  const handleStreamButton = () => {
    setIsPlaying(!isPlaying);
  };

  useImperativeHandle(ref, () => ({
    loadMixcloud: url => {
      setMixcloudShow(null);
      setMixcloudShow(
        "https://www.mixcloud.com/widget/iframe/?hide_cover=1&disableUnloadWarning=true&mini=1&light=1&startPlaying=true&feed=" +
          encodeURIComponent(url.replace("https://www.mixcloud.com", ""))
      );
    }
  }));

  useEffect(() => {
    if (mixcloudShow) {
      const script = document.createElement("script");
      script.src = "https://widget.mixcloud.com/media/js/widgetApi.js";
      document.body.appendChild(script);
    } else {
      setIsPlaying(false);
    }
  }, [mixcloudShow]);

  const onLoad = useCallback(() => {
    const widget = window.Mixcloud.PlayerWidget(document.getElementById("mixcloudEmbed"));
    widget.ready.then(function () {
      widget.play();

      widget.events.pause.on(() => {
        setIsPlaying(false);
      });

      widget.events.play.on(() => {
        setIsPlaying(true);
      });
    });
  }, [setIsPlaying]);

  const isLive = onair ? !!onair.hls : false;

  return (
    <div id="player" className={mixcloudShow ? "mixcloud" : "airtime"} ref={ref}>
      {mixcloudShow ? (
        <div id="mixcloud">
          <iframe
            title="mixcloud"
            id="mixcloudEmbed"
            width="100%"
            height="60"
            frameBorder="0"
            src={mixcloudShow}
            onLoad={onLoad}
          />
          <div className="right">
            <button type="button" onClick={e => setMixcloudShow(null)}>
              Back to live
            </button>
          </div>
        </div>
      ) : (
        <div id="stream">
          <div className="left">
            <div className="buttonAndTitle">
              <button type="button" disabled={!isLive} onClick={handleStreamButton}>
                {isPlaying ? <PauseButton /> : <PlayButton />}
              </button>
              <p>{onair ? (isLive ? onair.title : "Not broadcasting") : "Loading..."}</p>
            </div>
          </div>
          <div className="right">
            <p>{isLive ? (isMobile ? "Live" : "Now Live") : "Offline"}</p>
          </div>
          <div className="processorWrapper">
            <ReactPlayer
              wrapper="div"
              playing={isPlaying}
              url={onair && isPlaying ? onair.hls : undefined}
              width={0}
              height={0}
              config={{ file: { attributes: { preload: "none", autoPlay: false }, forceAudio: true } }}
            />
          </div>
        </div>
      )}
    </div>
  );
});

export default Player;
